/* eslint-disable no-irregular-whitespace */
<template>
  <div class="dialogContain">
    <el-dialog
      :visible.sync="mVisible"
      :show-close="false"
      custom-class="dialog cardDetail"
      @before-close="confirmModal"
    >
      <div class="areaWrap">
        <p class="areaTitle">{{ detail.enterpriseName }}</p>
        <div class="areaOne borderB">
          <div v-show="isArea == 1" class="row">
            <span>年营收额</span>
            <span>{{ detail.annualValue }}万元 人民币</span>
          </div>
          <div v-show="isArea == 2" class="row">
            <span>产业方向</span>
            <span>{{ detail.classifyicationName }}</span>
          </div>
          <div v-show="isArea == 1" class="row">
            <span>投资规模</span>
            <span>{{ detail.investment }}万元 人民币</span>
          </div>
          <div class="row">
            <span>项目类型</span>
            <!-- 人才落地 -->
            <span>{{ isArea == 1 ? "产业落地类" : "科技人才类" }}</span>
          </div>
        </div>
        <div
          v-show="detail.financed || detail.financedWay > -1"
          class="areaOne borderB areaTwo"
        >
          <div v-show="detail.financed" class="row">
            <span>融资总额</span>
            <span>{{ detail.financed }}万元 人民币</span>
          </div>
          <div v-show="detail.financedWay > -1" class="row">
            <span>融资方式</span>
            <span
              >{{ array[detail.financedWay] }}
              <span v-show="array[detail.financedWay] === '股权融资'"
                >{{ detail.equityRatio }}%</span
              ></span
            >
          </div>
        </div>
        <div v-show="isArea == 1" class="areaOne borderB areaThree">
          <div class="row">
            <span>落地需求</span>
            <div>
              <div v-show="detail.landDemand" class="row needRow">
                <span>土地需求</span> <span>{{ detail.landDemand }}亩</span>
              </div>
              <div v-show="detail.factoryWay > -1" class="row needRow">
                <span>厂房需求</span>
                <span
                  >{{ arArray[detail.factoryWay] }}
                  <span v-show="detail.factoryDemand"
                    >{{ detail.factoryDemand }}平方</span
                  ></span
                >
              </div>
              <div v-show="detail.deviceDemand" class="row needRow">
                <span>设备需求</span> <span>{{ detail.deviceDemand }}平方</span>
              </div>
              <div v-show="detail.officeDemand" class="row needRow">
                <span>办公需求</span> <span>{{ detail.officeDemand }}平方</span>
              </div>
              <div v-show="detail.supplement" class="row needRow textareaRow">
                <span>其他需求</span>
                <span class="textAreaContent">{{ detail.supplement }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-show="isArea == 2" class="areaOne borderB areaThree">
          <div class="row">
            <span>团队成员</span>
            <div>
              <div
                v-for="(item, index) in detail.projectTeams"
                :key="item.name + index"
              >
                <span>{{ item.name }}</span>
                <span>{{ educationList[item.education] }}</span>
                <span>{{ positionList[item.position] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="
            isArea == 2 &&
            detail.projectPatents &&
            detail.projectPatents.length > 0
          "
          class="areaOne borderB areaThree"
        >
          <div
            class="row textareaRow"
            v-if="detail.projectPatents && detail.projectPatents.length > 0"
          >
            <span>专利列表</span>
            <div>
              <div
                v-for="(item, index) in detail.projectPatents"
                :key="item.patentNo"
                class="patentWrap"
              >
                <span class="patentName">专利{{ index + 1 }}</span>
                <div class="patentContentWrap">
                  <p>专利号:{{ item.patentName }}</p>
                  <p>申请号:{{ item.patentNo }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="areaOne areaTwo">
          <div class="row">
            <span>联系人</span>
            <span>{{ detail.contactName }}</span>
          </div>
          <div class="row">
            <span>联系电话</span>
            <span>{{ detail.contactMobile }}</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button class="confirm" @click="confirmModal">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getProjectDetail } from "../../apis/user";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isArea: {
      type: Number,
      default: 1,
    },
    projectId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      detail: {},
      array: ["天使融资", "股权融资", "债券融资"], // 融资方式
      arrayArea: ["股权融资", "债权融资", "定向增发", "可转债券"],
      arArray: ["租赁", "购买", "先租后买", "代建", "自建"], // 厂房需求金额
      positionList: [
        "创始人",
        "合伙人",
        "CEO",
        "COO",
        "CTO",
        "CFO",
        "CMO",
        "技术主管",
        "生产主管",
        "营销主管",
        "财务主管",
      ],
      educationList: [
        "学士",
        "硕士",
        "博士",
        "院士",
        "海归学士",
        "海归硕士",
        "海归博士",
      ],
    };
  },
  computed: {
    mVisible: {
      get() {
        return this.visible;
      },
      set(s) {
        this.$emit("update:visible", s);
      },
    },
  },
  created() {
    if (this.isArea === 1) {
      this.array = this.arrayArea;
    }
    this.getProjectDetail(this.projectId);
  },

  methods: {
    // 保存dialog
    confirmModal() {
      this.$emit("update:visible", false);
      // this.canScroll()
    },
    async getProjectDetail(projectId) {
      var res = await getProjectDetail({ projectId });
      // //console.log(res)
      this.detail = res.result;
      if (this.isArea === 1) {
        this.detail.financedWay = this.detail.financedWay - 1;
      }
    },
  },
};
</script>
<style lang="scss">
.cardDetail {
  min-height: 700px;
}
</style>
<style lang="scss" scoped>
.areaWrap {
  // height:101%;
  padding: 0 14px;
  .areaTitle {
    margin-top: 10px;
    font-size: 24px;
    line-height: 1;
    color: #333333;
    padding-bottom: 10px;
  }
  .areaOne {
    padding: 16px 0 10px 0;
    .row {
      min-height: 56px;
      display: flex;
      align-items: center;
    }
    .row > span:nth-of-type(1) {
      min-width: 114px;
      font-weight: bold;
    }
  }
  .areaThree .row {
    align-items: flex-start;
    span {
      line-height: 56px;
    }
  }
  .areaOne .textareaRow,
  .areaThree .textareaRow {
    align-items: flex-start;
    padding: 10px 0;
    span {
      line-height: 28px !important;
    }
  }
  .areaOne .needRow > span:nth-of-type(1) {
    min-width: 80px;
    color: #999;
  }
  .textAreaContent {
    white-space: pre-wrap;
  }
  .borderB {
    border-bottom: 1px solid #eee;
  }
}
.el-button {
  margin-top: 30px;
  input {
    font-size: 14px;
  }
}
.patentWrap {
  line-height: 28px;
  .patentName {
    font-weight: bold;
  }
  .patentContentWrap {
    margin-left: 10px;
  }
}
</style>
